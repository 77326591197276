// approach found here https://wirewhiz.com/teleporting/
AFRAME.registerComponent("waypoint", {
  schema: {
    offset: { type: "number", default: 0 },
  },
  init: function (data) {
    var offset = this.data.offset;
    this.el.addEventListener("click", function (evt) {
      // first reset all waypoints to be visible
      const waypoints = document.querySelectorAll(".waypoint");
      Array.from(waypoints).forEach((waypoint) => {
        waypoint.setAttribute('visible', 'true');
      });
      // get element CSS class and check if it included ".standInFront"
      // basically special handling of the birds eye view
      const inFront = this.classList.contains("standInFront");
      let posZ = this.getAttribute("position").z;
      if (inFront) {
        posZ = posZ - 9;
      }
      document
        .querySelector("a-scene")
        .querySelector("#cameraWrapper")
        .setAttribute("position", {
          x: this.getAttribute("position").x,
          y: this.getAttribute("position").y,
          z: posZ,
        });
        // then set the current waypoint to be invisible so that it doesn't stand in the way
        evt.target.setAttribute('visible', 'false');
    });
  },
});
